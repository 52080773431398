<template>
  <div>
    <ml-container>
      <page-title class="tw-text-black tw-text-center">Promotions</page-title>

        <Promotions :showTerms="showPromoTerms" :showChecked="this.$route.query.checked ? Boolean(1):Boolean(0)">
          <template v-slot:terms_link>
            <div class="tw-cursor-pointer tw-col-span-1  tw-py-5" @click="showPromoTerms = !showPromoTerms">
              <div class="tw-flex tw-items-center tw-justify-start">
                <span class="tw-font-semibold tw-text-xs">{{!showPromoTerms ? 'Show '+$t('legal.terms'):'Hide '+$t('legal.terms')}}</span>
              </div>
            </div>
          </template>
        </Promotions>
    </ml-container>

  </div>
</template>

<script>



import Promotions from '@/components/Promotions'
import MlContainer from "@/components/layout/MlContainer";
import PageTitle from "@/components/layout/titles/PageTitle";
export default {
  data(){
    return{
      showPromoTerms: false
    }
  },
  components: {
    Promotions,MlContainer,PageTitle
  },
  methods:{
  }
}
</script>
